import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.initDragAndDrop();
  }

  initDragAndDrop() {
    // Add draggable attributes and event listeners to child elements
    this.containerTarget.querySelectorAll('[data-id]').forEach((item) => {
      item.setAttribute('draggable', true);
      item.addEventListener('dragstart', this.handleDragStart.bind(this));
      item.addEventListener('dragover', this.handleDragOver.bind(this));
      item.addEventListener('drop', this.handleDrop.bind(this));
      item.addEventListener('dragend', this.handleDragEnd.bind(this));
    });
  }

  handleDragStart(event) {
    event.dataTransfer.setData('text/plain', event.target.dataset.id);
    event.currentTarget.classList.add('dragging');
  }

  handleDragOver(event) {
    event.preventDefault();
    event.currentTarget.classList.add('drag-over');
  }

  handleDrop(event) {
    event.preventDefault();
    var draggedId = event.dataTransfer.getData('text/plain');
    var draggedElement = this.containerTarget.querySelector(`[data-id="${draggedId}"]`);
    var dropTarget = event.currentTarget;

    if (draggedElement !== dropTarget) {
      // Insert the dragged element before or after the drop target
      var container = this.containerTarget;
      var draggedPosition = Array.from(container.children).indexOf(draggedElement);
      var dropPosition = Array.from(container.children).indexOf(dropTarget);

      if (draggedPosition < dropPosition) {
        dropTarget.after(draggedElement);
      } else {
        dropTarget.before(draggedElement);
      }
    }

    this.updatePositions(); // Update positions after drop
  }

  handleDragEnd(event) {
    event.currentTarget.classList.remove('dragging', 'drag-over');
  }

  updatePositions() {
    // Update data-position attributes based on the current order
    Array.from(this.containerTarget.children).forEach((child, index) => {
      child.dataset.position = index + 1;
    });
  }

  savePositions(event) {
    event.preventDefault();
    var updatedPositions = Array.from(this.containerTarget.children).map((child) => {
      return { id: child.dataset.id, position: child.dataset.position };
    });

    // Make an AJAX call to update the positions
    $.ajax({
      url: '/superadmin/questions/update_positions',
      type: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      data: JSON.stringify({ questions: updatedPositions }),
      success: (data) => {
        console.log('Positions updated successfully:', data);
        this.successNotify('Positions updated successfully!');
      },
      error: (xhr, status, error) => {
        console.error('Error updating positions:', error);
        this.errorNotify('Failed to update positions. Please try again.');
      }
    });
  }

  successNotify(message) {
    Lobibox.notify('success', {
      showClass: 'fadeInDown',
      hideClass: 'fadeUpDown',
      width: 400,
      sound: false,
      icon: false,
      msg: message,
      position: 'top right',
    });
  }

  errorNotify(message) {
    Lobibox.notify('error', {
      showClass: 'fadeInDown',
      hideClass: 'fadeUpDown',
      width: 400,
      sound: false,
      icon: false,
      msg: message,
      position: 'top right',
    });
  }
}
