import { Controller } from "stimulus"

export default class extends Controller {
  FilterByCustomDate(event) {
    var form = event.currentTarget.closest('.customDateFilterForm')
    var filter_type = form.elements["type"].value;
    var start_date = form.elements["start_date"].value;
    var end_date = form.elements["end_date"].value;

    if (start_date == '' || end_date == '') {
      alert('Invalid Filter Dates');
    } else if (!['first', 'plus'].includes(filter_type)) {
      alert("Something Went Wrong")
    } else {
      var csrfToken = $('meta[name="csrf-token"]').attr('content');

      let data = {
        filter_type: filter_type,
        start_date: start_date,
        end_date: end_date
      }

      $.ajax({
        url: `/filter_reports_by_date`,
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        success: function (response) {
          event.target.closest(".card-body").querySelector('.card-text').textContent = response
        },
        error: function (xhr, status, error) {
          console.error("Error:", status, error);
        }
      })
    }
  }
}
