import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "couponcode", "couponerror", "loader", "discountamount"]

  connect(){
    console.log("Coupon Code Created..")
  }

  validateCouponCode(){
    let couponcode = this.couponcodeTarget.value.trim();
    if ( couponcode == '' ) {
      this.couponerrorTarget.textContent = "* Please Fill Coupon Code"
    } else {
      this.loaderTarget.classList.remove("d-none");
      this.couponerrorTarget.textContent = ""
      this.verify(couponcode);
    }
  }

  verify(couponcode){
    $.ajax({
      url: `/superadmin/coupons/verify`,
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify({couponcode: couponcode}),
      success: (response)=> {
        switch (response.code) {
          case 1:
            this.successNotify("Coupon Code has been applied successfully");
            this.discountamountTarget.textContent = `You will get discount of £${response.amount_off}`;
            this.loaderTarget.classList.add("d-none");
            break;
          case 2:
            this.errorNotify("Sorry! This coupon has been expired");
            this.loaderTarget.classList.add("d-none");
            break;
          default:
            this.errorNotify("Sorry! This coupon code is invalid. Please try again");
            this.loaderTarget.classList.add("d-none");
            break;
        }        
      },
      error: (xhr, status, error)=> {
        this.errorNotify(error);
      }
    })
  }

  successNotify(message){
		Lobibox.notify('success', {
			showClass: 'fadeInDown',
			hideClass: 'fadeUpDown',
			width: 400,
			sound: false,
			icon: false,
			msg: message,
			position: 'top right',
		});
	}

	errorNotify(message){
		Lobibox.notify('error', {
			showClass: 'fadeInDown',
			hideClass: 'fadeUpDown',
			width: 400,
			sound: false,
			icon: false,
			msg: message,
			position: 'top right',
		});
	}
}
